// src/App.js
import React, { useEffect, useState } from 'react';
import { Box, Heading, Flex, Select } from '@chakra-ui/react';
import Album from './Album';

const App = () => {
  const [albums, setAlbums] = useState([]);
  const [maxAlbumsPerRow, setMaxAlbumsPerRow] = useState(window.innerWidth <= 500 ? 2 : 5); // Default to 5 albums per row

  useEffect(() => {
    fetch('/api/albums')
      .then(response => response.json())
      .then(data => setAlbums(data));
  }, []);


  const handleMaxAlbumsChange = (event) => {
    setMaxAlbumsPerRow(Number(event.target.value));
  };

  return (
    <Box p={5} bg="gray.100" minH="100vh">
      <Heading as="h1" mb={5} textAlign="center" color="teal.500">
        The /v/oombox
      </Heading>
      <Flex justify="center" mb={5}>
        <Select width="auto" value={maxAlbumsPerRow} onChange={handleMaxAlbumsChange}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
            <option key={num} value={num}>
              {num} albums per row
            </option>
          ))}
        </Select>
      </Flex>
      {Object.entries(albums).map(([year, data]) => (
        <Album
          key={year}
          albums={data[1].albums || []}
          year={data[0]}
          download={data[1].download} 
          maxAlbumsPerRow={maxAlbumsPerRow}
          blockScrollOnMount={false}
        />
      ))}
    </Box>
  );
};

export default App;
