/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
} from '@chakra-ui/react'
import { isPastTime, timeStringToSeconds } from '../../utils/utils';

const LyricsDrawer = ({
  isLDOpen,
  onLDClose,
  lyrics,
  currentTime,
  handleSeek,
  songEnded,
  resetLyrics,
  setResetLyrics,
}) => {

  const btnRef = useRef();
  const currentLyricIndex = useRef(undefined);
  const [currentBodyRef, setCurrentBodyRef] = useState();
  const drawerBodyRef = useCallback(element => {
    if (element !== null) {
      setCurrentBodyRef(element);
    }
  }, []);
  const [lyricDictionary, setLyricDictionary] = useState(null);
  const [lyricTimeDict, setLyricTimeDict] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const ref = useRef(document.body)
  useEffect(() => {
    if (isLDOpen) {
      ref.current.style.overflow = 'hidden';
      ref.current.style.touchAction = 'none';
    } else {
      ref.current.style.overflow = 'visible';
      ref.current.style.touchAction = 'auto';
    }
  }, [isLDOpen]);

  useEffect(() => {
    if (lyrics) {
      let lyricMap = new Map();
      let lyricTimeMap = new Map();
      // eslint-disable-next-line array-callback-return
      lyrics.map((lyricContent, index) => {
        lyricMap.set(index, lyricContent);
        if (lyricContent.indexOf('[') > -1) {
          lyricTimeMap.set(
            index, 
            timeStringToSeconds(
              lyricContent.substring(lyricContent.indexOf('[') + 1,
              lyricContent.indexOf(']'))
            )
          );
        }
      });
      setLyricTimeDict(lyricTimeMap);
      setLyricDictionary(lyricMap);
    } else {
      setLyricDictionary(undefined);
    }
    currentLyricIndex.current = undefined;
  }, [lyrics]);

  useEffect(() => {
    setLyricDictionary(lyrics);
    currentLyricIndex.current = undefined;
  }, [lyrics]);
  
  const findClosestIndexUnder = (map, targetValue) => {
    let closestIndex = null;
    let closestValue = -Infinity;

    if (map?.entries()) {
      for (const [index, value] of map.entries()) {
          if (parseFloat(value) < targetValue && parseFloat(value) > closestValue) {
              closestValue = value;
              closestIndex = index;
          }
      }
    }

    return closestIndex;
  }

  useEffect(() => {
    if (resetLyrics && lyricTimeDict) {
      const closestIndex = findClosestIndexUnder(lyricTimeDict, currentTime);
      currentLyricIndex.current = closestIndex;
    }
    setResetLyrics(false);
  }, [resetLyrics])

  useEffect(() => {
    if (lyricDictionary) {
      if (currentLyricIndex.current !== undefined) {
        let nextLyric = lyricDictionary[currentLyricIndex.current + 1];
        let twoIndexAhead = lyricDictionary[currentLyricIndex.current + 2];
        
        if (
          nextLyric &&
          nextLyric !== '\n' &&
          isPastTime(
            currentTime,
            nextLyric.substring(nextLyric.indexOf('[') + 1, nextLyric.indexOf(']'))
          )
        ) {
          currentLyricIndex.current += 1;
        } else if (
          nextLyric === '\n' &&
          twoIndexAhead &&
          isPastTime(
            currentTime,
            twoIndexAhead.substring(twoIndexAhead.indexOf('[') + 1, twoIndexAhead.indexOf(']'))
          )
        ) {
          currentLyricIndex.current += 2;
        }
        if (!hasScrolled) {
          const element = document.getElementById(`lyric-line-${currentLyricIndex.current}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      } else if (lyricDictionary?.[0]) {
        if (
          isPastTime(currentTime,
            lyricDictionary[0].substring(lyricDictionary[0].indexOf('[') + 1, lyricDictionary[0].indexOf(']')))
        ) {
          currentLyricIndex.current = 0;
        }
      }
    }
  }, [currentTime, lyricDictionary, currentLyricIndex]);

  useEffect(() => {
    currentLyricIndex.current = undefined;
  }, [songEnded]);

  useEffect(() => {
    const handleScroll = () => {
      const element = currentBodyRef;
      if (element) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    if (currentBodyRef) {
      if (currentBodyRef) {
        currentBodyRef.addEventListener('wheel', handleScroll);
        currentBodyRef.addEventListener('touchmove', handleScroll);
      }
  
      // Cleanup the event listener on component unmount or if the drawer is closed
      return () => {
        if (currentBodyRef) {
          currentBodyRef.removeEventListener('wheel', handleScroll);
          currentBodyRef.removeEventListener('touchmove', handleScroll);
        }
      };
    }

  }, [isLDOpen, currentBodyRef]);

  useEffect(() => {
    if (hasScrolled) {
      const timeoutId = setTimeout(() => {
        setHasScrolled(false);
      }, 1500);

      return () => clearTimeout(timeoutId);
    }
  }, [hasScrolled]);

  const lyricColorFunction = (lyricIndex) => {
    if (lyricIndex < currentLyricIndex.current) {
      return 'lightgrey'
    } else if (lyricIndex === currentLyricIndex.current) {
      return 'orange'
    }
  }

  const lyriWeightFunction = (lyricIndex) => {
    if (lyricIndex < currentLyricIndex.current) {
      return 200
    } else if (lyricIndex === currentLyricIndex.current) {
      return 700
    }
  }

  const handleLyricLineClick = (lyricLineTimestamp, index) => {
    if (lyricLineTimestamp) {
      currentLyricIndex.current = index;
      handleSeek(timeStringToSeconds(lyricLineTimestamp));
    }
  }

  const prettyPrintLyric = (lyricLine) => {
    return lyricLine.indexOf(']') > -1 ? lyricLine.substring(lyricLine.indexOf(']') + 1, lyricLine.length) : lyricLine;
  }

  const getCursorClass = (lyricLine) => {
    return lyricLine.indexOf(']') > -1 ? 'pointer' : 'auto'
  }

  const handleCloseDrawerReset = () => {
    onLDClose();
  }

  return (
    <>
      <Drawer
        isOpen={isLDOpen}
        placement='right'
        onClose={handleCloseDrawerReset}
        finalFocusRef={btnRef}
        size='sm'
        blockScrollOnMount={false}
      >
        <DrawerOverlay overflow='scroll' >
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Lyrics</DrawerHeader>

            <DrawerBody ref={drawerBodyRef}>
              {lyrics?.map((lyricLine, index) => lyricLine !== '\n' ? (
                (
                  <>
                    <Box
                      textAlign='center'
                      id={`lyric-line-${index}`}
                      textColor={lyricColorFunction(index)}
                      fontWeight={lyriWeightFunction(index)}
                      onClick={() => handleLyricLineClick(lyricLine.substring(lyricLine.indexOf('[') + 1, lyricLine.indexOf(']')), index)}
                      cursor={getCursorClass(lyricLine)}
                    >
                      {prettyPrintLyric(lyricLine)}
                    </Box>
                  </>
                )
              ) : (
                <br />
              ))}
            </DrawerBody>
            <DrawerFooter>
              <Button onClick={handleCloseDrawerReset}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

export default LyricsDrawer;